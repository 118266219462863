<template>
  <div class="offer-letter">
    <template v-if="canViewOfferLetter">
      <div>
        <div class="text-right mb-10">
          <logo class="inline-block" />
        </div>

        <div class="text-center text-2xl uppercase font-bold mb-10">
          LOAN AGREEMENT
        </div>

        <div class="text-14 leading-relaxed">
          <div class="mt-5 mb-5">
            This Loan Agreement (this “Agreement”), is executed on
            <span class="font-bold">{{
              offerLetter.created_at | dateFormat('D, dd M, Y')
            }}</span
            >.
          </div>

          <div class="mb-5">by and between</div>

          <div class="mb-5">
            <span class="font-bold">{{ name }}</span
            >, located at <span class="font-bold">{{ address }}</span
            >, with CredPal User ID
            <span class="font-bold">{{ offerLetter.user_id }}</span> hereinafter
            referred to as the <span class="font-bold">“Borrower”</span> which
            expression shall, where the context so admits, mean and include its
            legal representatives, assignee, nominee(s) and administrator;
          </div>

          <div class="mb-5">and</div>

          <div class="mb-5">
            <span class="font-bold">Crednet Technologies Limited</span>, a
            company duly incorporated under the laws of the Federal Republic of
            Nigeria, located at 75, Olonode Street, Alagomeji, Yaba, Lagos
            State, hereinafter referred to as the
            <span class="font-bold">“Lender” or “CredPal”</span> which
            expression shall, where the context so admits, mean and include its
            legal representatives, assignee, nominee(s) and administrator;
          </div>

          <div class="mb-5">
            WHEREAS at the request of the Borrower, the Lender agrees to lend to
            the Borrower the sum of Loan Amount
            <span class="font-bold"
              >({{ requestedAmount | formatAmount }})</span
            >
            for a period of {{ loanTenure }}
            {{ 'month' | toPlural(loanTenure) }} (“Tenor”) on the terms and
            conditions set out below.
          </div>

          <div class="mb-5">The parties agree as follows:</div>

          <ol class="list">
            <li>
              <span class="font-bold">Loan Amount:</span> The Lender agrees to
              loan the Borrower the sum of {{ requestedAmount | formatAmount }},
              together with interest on the principal amount of the Loan (the
              <span class="font-bold">&quot;Principal Balance&quot;</span>), in
              accordance with the terms below and a repayment schedule which the
              Lender will have communicated to the Borrower.
            </li>
            <li>
              <span class="font-bold">Interest:</span> The Principal Balance
              shall bear interest at the rate of {{ loanInterest }}% per month,
              accruing daily. The interest shall be calculated using a flat rate
              based on the Loan amount originally disbursed to the Borrower. In
              the event that CredPal revises the rate of interest payable on the
              Loan, the Borrower shall be duly notified ten (10) days prior to
              the implementation of said variation.
            </li>
            <li>
              <span>Repayment of Loan:</span> The Loan, together with accrued
              and unpaid interest and all other charges, costs and expenses, is
              due and payable on or before
              <span class="font-bold">{{
                lastRepaymentDate | dateFormat('D, dd M, Y')
              }}</span
              >, (the <span class="font-bold">“Maturity Date”</span>). The
              Borrower agrees to repay the Principal Balance (Loan+interest) as
              displayed on the Borrower’s Dashboard, i.e., by making a monthly
              installment of
              <span class="font-bold">N{{ repaymentAmount | currency }}</span>
              starting on
              <span class="font-bold">{{
                firstRepaymentDate | dateFormat('D, dd M, Y')
              }}</span>
              (being the first “Repayment Date”) and continuing monthly
              throughout the Tenor, provided that where CredPal disburses a Loan
              to the Borrower during the course of a spent calendar month, the
              first repayment amount may be lesser than subsequent repayments.

              <div class="mt-5">
                CredPal, may at its sole discretion, grant the Borrower a 3-day
                grace period after a Repayment Date or the Maturity Date. Where
                the grace period elapses, the Borrower will be liable to pay the
                default charge that would have ordinarily accrue but for the
                grace period.
              </div>

              <div class="mt-5 font-bold italic">
                Where the Borrower fails to make a complete repayment on a
                Repayment Date or the Maturity Date, CredPal will charge default
                interest at a rate of 0.5% per day on the original overdue
                amount payable to CredPal, which shall continue to accrue day to
                day until the overdue amount is paid in full.
              </div>
            </li>
            <li>
              <span class="font-bold">Prepayment:</span> The Borrower may prepay
              part of the Loan, together with accrued and unapplied interest
              thereon, at any time. Where the customer prepays his/her loan
              before a Repayment Date or Maturity Date, the amount prepaid will
              be applied to any accrued interest, unapplied interest, default
              charges, and to offset the Loan for the current period, such that
              any amount left from a prepayment (if any) will be applied to the
              outstanding Loan and interest accruing for subsequent repayments.

              <div class="mt-5">
                Prepayment of the Principal Balance shall mean that the
                Borrower’s outstanding balance shall resynchronize into the
                monthly installment cycle, as set out in Clause 3 above (i.e.,
                the next instalment shall become payable on the next Repayment
                Date and the Maturity Date shall move forward to the last
                Repayment Date of the outstanding balance).
              </div>

              <div class="mt-5">
                Prepayment that amounts to early part-liquidation may attract a
                penal charge, to be determined by the Lender.
              </div>

              <div class="mt-5">
                Where the Borrower decides to make a repayment on the Loan
                before a Repayment Date,, such repayment shall be made at least
                three (3) clear business days before the Repayment Date, and the
                Borrower shall communicate such repayment to CredPal, within 24
                hours following repayment via email at
                <span class="font-bold">collections@credpal.com</span>. This is
                to enable the Lender process and update the Borrower’s
                information. Failure to adhere to this timeline/process entitles
                the Lender to present the Borrower’s repayment instrument for
                processing in line with the applicable clearing guidelines.
              </div>
            </li>
            <li>
              <span class="font-bold">Costs and Expenses:</span> Borrower agrees
              to pay to the Lender all costs and expenses incurred in processing
              the Loan—including
              <span class="font-bold italic"
                >a Management Fee of 1.5% of the Loan</span
              >—and any costs which the Lender incurs in recovering any
              outstanding balance or of enforcing this Agreement (including
              legal expenses).

              <div class="mt-5">
                The Borrower agrees to pay the Management Fee in advance and
                hereby authorizes the Lender to deduct the Management Fee from
                the Loan at the point of disbursement.
              </div>
            </li>
            <li>
              <span class="font-bold">Default:</span> Notwithstanding anything
              else contained in this Agreement, the whole of the Principal
              Balance or so much of it as for the time being remains outstanding
              and all interest accrued but unpaid shall immediately become
              repayable in any of the following events:

              <ul class="list mt-5">
                <li>
                  Where a bankruptcy petition is filed against the Borrower.
                </li>
                <li>
                  Where fraud is detected at any time during the Loan Tenor.
                </li>
                <li>
                  Where a situation arises, which in CredPal’s opinion, makes it
                  inappropriate to continue to extend the Loan facility to the
                  Borrower.
                </li>
                <li>
                  Where the Borrower breaches any of the terms and conditions of
                  the Loan including where a repayment instrument is returned
                  unpaid.
                </li>
                <li>
                  Where the Borrower is unable to pay any other party within the
                  meaning of Section 1 of the Bankruptcy Act (Cap 30) Laws of
                  the Federation of Nigeria.
                </li>
                <li>
                  The Borrower fails to make a repayment or payment of Loan,
                  interest or other amount in respect of the Loan on the date it
                  was due to be paid.
                </li>
                <li>
                  Where the Borrower defaults in the performance or observance
                  of any other term, condition or covenant herein and such
                  breach or default continues unremedied after ten (10) days’
                  notice have been given to the Borrower.
                </li>
              </ul>
              <div class="mt-5">
                In the case of default, CredPal reserves the right to explore
                every legal option to recover the Loan and any outstanding
                balance.
              </div>
            </li>
            <li>
              <span class="font-bold">Liquidation:</span> The Borrower may
              liquidate the Principal Balance in part or in full before the
              Maturity Date, subject to liquidation charge applied by the
              Lender. Liquidation can be done on the CredPal mobile application
              or the Borrower may communicate his/her intention to liquidate to
              the Lender, upon which the Lender will communicate the charge
              payable on such liquidation to the Borrower. Where the Borrower
              fails to communicate his/her intention or fails to pay the charge
              imposed by the Lender, any purported liquidation will be treated
              as a prepayment, as outlined in the first paragraph of Clause 4
              above.

              <div class="mt-5">
                Full or part liquidation of the Principal Balance by the
                Borrower a month to the Maturity Date shall incur no penal
                charges.
              </div>
            </li>
            <li>
              <span class="font-bold">Representations &amp; Warranties:</span>
              The Borrower represents and warrants that:
              <ul class="mt-5">
                <li>he/she is in paid employment;</li>
                <li>
                  there is no legal case in progress or pending against him/her
                  that may impair his/her ability torepay the Loan;
                </li>
                <li>
                  he/she has full ownership over an asset which he/she has
                  offered as collateral (where applicable);
                </li>
                <li>
                  all information provided to the Lender in order to obtain this
                  Loan are accurate, complete, and true. Where the Borrower’s
                  circumstances/information changes, the Borrower undertakes to
                  immediately inform CredPal via email at
                  <span class="font-bold">hello@credpal.com</span>. Failure to
                  do so shall be treated as willful misrepresentation and fraud
                  and the Borrower may be prosecuted under relevant laws while
                  the outstanding loan balance shall be called in immediately.
                </li>
              </ul>
            </li>
            <li>
              <span class="font-bold">Disclosures:</span> The Borrower
              authorizes CredPal to disclose any information or documentation
              relating to the Loan and the Borrower (including the Borrower’s
              credit history and personal information) to third parties
              including the Lender’s financiers (e.g., VGG Group and Assured
              MFB), credit reference agencies, Borrower’s employers (where the
              Borrower is in salaried employment), collection agencies, law
              enforcement agencies including but not limited to the Economic and
              Financial Crimes Commission and the Special Fraud Unit of the
              Nigerian Police Force, to satisfy regulatory directives and
              especially in the events that the Loan has ceased to be serviced
              by the Borrower and/or where the Lender has decided to institute
              legal actions against the Borrower for the purpose of recovering
              the Loan and all outstanding balance, or where the Lender
              reasonably believes that the Borrower is liable for willful
              misrepresentation and/or fraud.
            </li>
            <li>
              <span class="font-bold">Set-Off:</span> The Lender may at any time
              with or without notice (a) combine or consolidate some or all of
              the Borrower’s account(s) without any liability to CredPal and/or
              (b) set off and transfer any sum standing to the credit of any
              such account in full or partial payment of any amount the Borrower
              owes to CredPal.

              <div class="mt-5">
                This clause is in addition to and does not amend or qualify any
                other present or future right of CredPal to combine or set off
                any of the Borrower’s accounts with it.
              </div>
            </li>
            <li>
              <span class="font-bold">Payment to be Gross:</span> All payments
              to be made by the Borrower under this Agreement shall be made
              without set-off or counterclaim, free of all taxes and without
              deduction for or on account of any present or future taxes, unless
              it is compelled by law to make payment subject to any taxes, in
              which event the payment shall be increased by the amount necessary
              to ensure that the Lender receives a net amount, free and clear of
              taxes, equal to the full amount which the Lender would have
              received had the payment not been subject to any taxes.
            </li>
            <li>
              <span class="font-bold"
                >Incorporation of CredPal’s Terms and Conditions:</span
              >
              All terms, provisions, and conditions—including any modification,
              addition, removal, alteration, reconstruction—in the Terms and
              Conditions (T&amp;Cs) available on CredPal&#39;s website are
              incorporated into this Agreement with the same force and effect as
              though fully set forth herein. Both the T&amp;Cs and this
              Agreement together shall be construed as one and the same
              agreement. Where the terms of the T&amp;Cs are inconsistent with
              the terms of this Agreement, the terms of the T&amp;Cs shall
              prevail.
            </li>
            <li>
              <span class="font-bold">No Waiver:</span> The Lender shall not be
              deemed to have waived any provision of this Agreement or the
              exercise of any rights held under this Agreement unless such
              waiver is made expressly and in writing. Waiver by the Lender of a
              breach or violation of any provision of this Agreement shall not
              constitute a waiver of any other subsequent breach or violation.
            </li>
            <li>
              <span class="font-bold">Severability:</span> In the event that any
              of the provisions of this Agreement are held to be invalid or
              unenforceable in whole or in part, the remaining provisions shall
              not be affected and shall continue to be valid and enforceable as
              though the invalid or unenforceable parts had not been included in
              this Agreement. If any term or provision of this Agreement is
              determined to be illegal, unenforceable, or invalid in whole or in
              part for any reason, such illegal, unenforceable, or invalid
              provisions or part(s) of the Agreement shall be stricken off, and
              such provision shall not affect the legality, enforceability, or
              validity of the remainder of this Agreement.
            </li>
            <li>
              <span class="font-bold">Assignment:</span> The Borrower shall not
              assign this Agreement, in whole or in part, without the written
              consent of the Lender. The Lender may assign all or any portion of
              this Agreement with written notice to Borrower.
            </li>
            <li>
              <span class="font-bold">General:</span>
              <ul class="mt-4">
                <li>
                  The Borrower is permitted to rescind this offer within seven
                  (7) days from the date of execution of this offer letter,
                  provided CredPal has not disbursed the Loan to the Borrower.
                  If CredPal has disbursed the Loan to the borrower, the
                  Borrower can terminate this Agreement by repaying the entire
                  Loan facility within 24hours of disbursement.
                </li>
                <li>
                  The Loan offer and this Agreement is conditional upon the
                  provision of a satisfactory credit report and further
                  verification checks.
                </li>
                <li>
                  CredPal reserves the right to withdraw the Loan offer in the
                  event that the results of the credit and verification checks
                  are unsatisfactory.
                </li>
                <li>
                  This Agreement contains the entire agreement and understanding
                  between the Borrower and Lender in relation to its subject
                  matter and supersedes any other prior agreement made between
                  the parties.
                </li>
                <li>
                  CredPal reserves the right at all times to vary the terms and
                  conditions of this Agreement. Any such variation will become
                  effective upon notice to the Borrower by any means CredPal
                  considers reasonable in the circumstance.
                </li>
              </ul>
            </li>
            <li>
              <span class="font-bold">Governing Law:</span> This Agreement is
              governed by Nigerian law and the courts of the Federal Republic of
              Nigeria shall have jurisdiction in any matter arising from it.
            </li>
          </ol>

          <div class="mt-5 font-bold">
            For and on behalf of CREDNET TECHNOLOGIES LIMITED
          </div>

          <div class="flex flex-col mt-5">
            <div class="inline-flex">
              <h5 class="text-13">Olumide Badewole</h5>
            </div>
            <span class="mt-2 text-13">Legal Officer</span>
            <span class="mt-2 text-13">{{ today }}</span>
          </div>

          <div class="mt-5">
            I {{ name }} have read and understood the terms of this Agreement
            and I agree to be bound by the Agreement. I declare that all the
            information I have provided in support of this loan application are
            accurate and complete.
          </div>

          <div class="mt-5">
            I hereby agree that by stating my full name below, I am
            electronically appending my signature.
          </div>

          <div class="flex justify-between mt-5 mb-12">
            <div class="flex flex-col">
              <h4 class="font-bold text-13">BORROWER SIGNATURE</h4>
              <h4 class="text-13 mt-8">{{ name }}</h4>
            </div>
            <div class="flex flex-col">
              <h4 class="font-bold text-13">Execution Date</h4>
              <span class="text-13 mt-3">{{ today }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="actions">
        <div class="mb-3 text-center">
          <!-- <div class="alert alert-gray items-start" v-if="status === 'pending'">
            <span class="alert-icon">!</span>
            <div class="text-left">
              <div class="font-bold">Pending Approval</div>
              <div class="font-thin">
                Officiis amet excepturi minima sunt commodi nostrum voluptatibus molestias aliquam facilis deleniti fugiat sapiente soluta accusantium, accusamus, officia nihil cumque fugit. Distinctio.
              </div>
            </div>
          </div> -->
          <div
            class="alert alert-green items-start"
            v-if="status === 'approved'"
          >
            <span class="alert-icon">!</span>
            <div class="text-left">
              <div class="font-bold">Approved</div>
              <div class="font-thin">
                If you agree to the terms and conditions above, your loan will
                be ready for disbursement to the registered bank account on your
                profile.
              </div>
            </div>
          </div>
          <!-- <div class="alert alert-red items-start" v-else-if="status === 'declined'">
            <span class="alert-icon">!</span>
            <div class="text-left">
              <div class="font-bold">Declined</div>
              <div class="font-thin">
                et illum deserunt itaque magnam amet, assumenda enim minus dolore quaerat ducimus pariatur adipisci tempora voluptate sapiente repellat repellendus aliquam doloribus.
              </div>
            </div>
          </div> -->
          <div
            class="alert alert-blue items-start"
            v-else-if="status === 'accepted'"
          >
            <span class="alert-icon">!</span>
            <div class="text-left">
              <div class="font-bold">Accepted</div>
              <div class="font-thin">
                You have accepted the terms and conditions of the offer letter
                sent above. Your loan will be disbursed to your account shortly.
              </div>
            </div>
          </div>
          <!-- <div class="alert alert-orange items-start" v-else-if="status === 'rejected'">
            <span class="alert-icon">!</span>
            <div class="text-left">
              <div class="font-bold">Rejected</div>
              <div class="font-thin">
                You have rejected the terms and conditions of the offer letter sent above. Your loan will no longer be processed.
              </div>
            </div>
          </div> -->
          <div
            class="alert alert-blue items-start"
            v-else-if="status === 'disbursed'"
          >
            <span class="alert-icon">!</span>
            <div class="text-left">
              <div class="font-bold">Active</div>
              <div class="font-thin">
                Your funds have been disbursed to your bank account. Be sure to
                make your repayment(s) on time.
              </div>
            </div>
          </div>
          <div
            class="alert alert-green items-start"
            v-else-if="status === 'paid'"
          >
            <span class="alert-icon">!</span>
            <div class="text-left">
              <div class="font-bold">Paid Off</div>
              <div class="font-thin">
                You have completly paid off this loan.
              </div>
            </div>
          </div>
        </div>

        <div>
          <button
            v-if="canAcceptOrReject"
            class="btn btn-orange mr-3"
            @click="reject"
            :disabled="form.loading"
          >
            <sm-loader
              class="sm-loader-white"
              v-if="form.loading && form.action === 'reject'"
            />
            <span v-else>Reject</span>
          </button>
          <button
            v-if="canAcceptOrReject"
            class="btn btn-blue mr-3"
            @click="accept"
            :disabled="form.loading"
          >
            <sm-loader
              class="sm-loader-white"
              v-if="form.loading && form.action === 'accept'"
            />
            <span v-else>Accept</span>
          </button>
          <button class="btn btn-yellow" @click="print">
            <span>Print</span>
          </button>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="pt-32">
        <div class="text-xl font-bold opacity-50 text-center">
          There is no offer letter for this loan!
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    offerLetter: {
      type: Object,
      default: () => {}
    },
    defaultInterestRate: {
      type: [Number, String],
      default: () => {}
    }
  },
  data() {
    return {
      form: this.$options.basicForm(['action'])
    };
  },
  computed: {
    address() {
      return this.offerLetter?.user_data?.address;
    },
    canAcceptOrReject() {
      return (
        this.offerLetter?.status?.match(/pending/) &&
        this.offerLetter?.user_id == this.user?.id
      );
    },
    canViewOfferLetter() {
      return true;
    },
    decided() {
      return this.offerLetter?.status?.match(/accepted|rejected/);
    },
    firstRepayment() {
      return this.repayments?.[0];
    },
    lastRepayment() {
      return this.repayments?.[this.repayments.length - 1];
    },
    lastRepaymentDate() {
      return this.offerLetter?.last_repayment_date;
    },
    firstRepaymentDate() {
      return this.offerLetter?.first_repayment_date;
    },
    loanAmount() {
      return this.offerLetter?.loan_amount;
    },
    loanInterest() {
      return this.offerLetter?.interest_rate;
    },
    loanTenure() {
      return this.offerLetter?.tenure;
    },
    name() {
      return `${this.offerLetter?.user_data?.first_name} ${this.offerLetter?.user_data?.last_name}`;
    },
    requestedAmount() {
      return this.offerLetter?.requested_amount;
    },
    disbursedAmount() {
      return this.offerLetter?.disbursed_amount;
    },
    today() {
      return this.$moment().format('Do, MMMM YYYY');
    },
    repayments() {
      return this.offerLetter?.repayment_structure;
    },
    repaymentAmount() {
      return this.offerLetter.repayment_amount;
    },
    salutation() {
      const gender = this.offerLetter?.user_data?.profile?.gender;
      switch (gender) {
        case 'male':
          return 'Sir';
        case 'female':
          return 'Ma';
        default:
          return 'Sir/Ma';
      }
    },
    status() {
      return this.offerLetter?.status;
    }
  },
  methods: {
    print() {
      window.print();
    },
    async accept() {
      this.form.error = null;
      this.form.action = 'accept';
      this.form.loading = true;
      await this.sendRequest(
        'personal.loans.offerLetter.accept',
        this.offerLetter?.id,
        {
          success: response => {
            this.$emit('accepted', response.data.loan);
            this.loadResource('loans', true);
          },
          error: error => {
            this.form.error = error;
          }
        }
      );
      this.form.loading = false;
      this.form.action = null;
    },
    async reject() {
      this.form.error = null;
      this.form.action = 'reject';
      this.form.loading = true;
      await this.sendRequest(
        'personal.loans.offerLetter.reject',
        this.offerLetter?.id,
        {
          success: response => {
            this.loadResource('loans', true);
            this.$emit('rejected', response.data.loan);
          },
          error: error => {
            this.form.error = error;
          }
        }
      );
      this.form.loading = false;
      this.form.action = null;
    }
  }
};
</script>

<style lang="sass" scoped>
.offer-letter
  max-width: 100%
  min-height: 500px
  width: 768px
  @media screen
    @apply bg-white mx-auto rounded-b pt-10 px-10 pb-20
    @apply border border-blue-200
    border-top: 5px solid theme('colors.blue.500')
    box-shadow: 0 10px 20px rgba(#000, .1)
  ol,
  ul
    li
      @apply mb-5 ml-4
      &.mb-0
        @apply mb-0
  ol
    list-style: decimal
    &.lower-alpha
      list-style: lower-alpha
  ul
    list-style: disc
  .placeholder
    @apply inline-block
    width: 350px
  .actions
    @media print
      @apply hidden
</style>
